import { v4 as uuidv4 } from "uuid";

 const NHinventory = [
  {
    title: "Custom NH h00die",
    price: 54.99,
    img: "NH2-2",
    description: "A wonderful Custom NH h00die",
    stripeKey: "price_1MdbrtLrVEr2jlD6Qmd5VDl2",
    id: uuidv4(),
  },
  {
    title: "Custom NH T-shirt",
    price: 22.99,
    img: "NH3-2",
    description: "An insane Custom NH h00die",
    stripeKey: "price_1MdbrtLrVEr2jlD6Qmd5VDl2",
    id: uuidv4(),
  },
  {
    title: "Custom NH Product",
    price: 17,
    img: "NH6-2",
    description: "Our first custom product #1.",
    stripeKey: "price_1MdbrtLrVEr2jlD6Qmd5VDl2",
    id: uuidv4(),
  },
];

export default NHinventory;